console.log('Ça marche!')
import Siema from 'siema';

const carousel = new Siema({
  duration: 1000,
  loop: true,
});

document.querySelector('.prev').addEventListener('click', () => carousel.prev());
document.querySelector('.next').addEventListener('click', () => carousel.next());
